import React, { useState } from 'react';
import './App.css';
import { FaSun, FaMoon } from 'react-icons/fa';

const App = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isNightMode, setIsNightMode] = useState(false);
  const [isCommaSeparated, setIsCommaSeparated] = useState(true);
  const [isAlphabetical, setIsAlphabetical] = useState(false);
  const [groupCount, setGroupCount] = useState(1); // New state for grouping number

  const handleInputChange = (e) => setInputText(e.target.value);

  const countEmails = (text) => {
    const emails = extractEmails(text);
    return emails.length;
  };

  const extractEmails = (text) => {
    const regex = /<([^>]+)>|"([^"]+)"|([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    const matches = text.match(regex) || [];
    return matches.map(match => match.replace(/[<>"]/g, '').toLowerCase());
  };

  const groupEmails = (emails, groupSize) => {
    let groupedEmails = [];
    for (let i = 0; i < emails.length; i += groupSize) {
      groupedEmails.push(emails.slice(i, i + groupSize));
    }
    return groupedEmails;
  };

  const handleRemoveDuplicates = (removeAll) => {
    const emails = extractEmails(inputText);
    const emailCounts = emails.reduce((counts, email) => {
      counts[email] = (counts[email] || 0) + 1;
      return counts;
    }, {});

    const uniqueEmails = Object.keys(emailCounts).filter(email =>
      removeAll ? emailCounts[email] === 1 : emailCounts[email] === 1 || emailCounts[email] > 1
    );

    if (groupCount > 1) {
      const groupedEmails = groupEmails(uniqueEmails, groupCount);
      const formattedGroups = groupedEmails.map(group =>
        isCommaSeparated ? group.join(', ') : group.join('\n')
      );
      setOutputText(isCommaSeparated ? formattedGroups.join('\n\n') : formattedGroups.join('\n\n\n'));
    } else {
      if (isAlphabetical) {
        uniqueEmails.sort();
      }
      setOutputText(isCommaSeparated ? uniqueEmails.join(', ') : uniqueEmails.join('\n'));
    }
  };

  const handleRemoveAllButtonClick = () => handleRemoveDuplicates(true);
  const handleRemoveDuplicatesButtonClick = () => handleRemoveDuplicates(false);

  const toggleNightMode = () => setIsNightMode(prevMode => !prevMode);

  return (
    <div className={`app ${isNightMode ? 'night-mode' : 'day-mode'}`}>
      <div className="header">
        <h1>Email Duplicate Remover</h1>
        <button onClick={toggleNightMode} className="mode-toggle">
          {isNightMode ? <FaSun /> : <FaMoon />}
        </button>
      </div>
      <div className="container">
        <div className="textarea-container">
          <textarea
            placeholder="Enter emails here..."
            value={inputText}
            onChange={handleInputChange}
            rows="10"
          />
          <div className="info">
            Total Emails Entered: {countEmails(inputText)}
          </div>
        </div>
        <div className="controls">
      
          <label>
            <input
              type="checkbox"
              checked={isCommaSeparated}
              onChange={() => setIsCommaSeparated(prev => !prev)}
            />
            Comma
          </label>
          <label>
            <input
              type="checkbox"
              checked={isAlphabetical}
              onChange={() => setIsAlphabetical(prev => !prev)}
            />
            A-Z
          </label>
          <label>
            Group
            <input
          
              type="number"
              value={groupCount}
              onChange={(e) => setGroupCount(Number(e.target.value))}
              min="1"
              style={{ width: '60px', padding: '5px', margin: '2px' }} // Adjust style as needed
            />
            
          </label>
          <button onClick={handleRemoveAllButtonClick}>Duplicate and original</button>
          <button onClick={handleRemoveDuplicatesButtonClick}>Duplicates only</button>

        </div>
        <div className="textarea-container">
          <textarea
            placeholder="Processed emails appear here..."
            value={outputText}
            readOnly
            rows="10"
          />
          <div className="info">
            Total Results: {extractEmails(outputText).length}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
